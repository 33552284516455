import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

import { FormattedMessage } from 'react-intl';
import CustomLink from '../Atoms/CustomLink';
import Row from '../Atoms/Row';
import Column from '../Atoms/Column';
import theme from '../../styles/theme';
import ItemWithDate from './Items/ItemWithDate';
import { pageUrls } from '../../config/pageSetting';
import Heading from '../Atoms/Heading';
import Box from '../Atoms/Box';
import ReadMoreButton from './ReadMoreButton';

class NewsAndEventShortRollUI extends React.Component {
  render() {
    const { edges: posts } = this.props.data.allMarkdownRemark;

    return (
      <>
        <Row multi>
          <Column col="is-12">
            <Heading h={2} pb={0} color={theme.color.blue}>
              <FormattedMessage id="newsAndEvent:shortRoll.news" defaultMessage="News And Events" />
            </Heading>
            <Box>
              {posts
                && posts.map(({ node: post }, index) => (
                  <Box
                    width={['100%', '100%', '100%', '100%', '50%']}
                    display="inline-block"
                    pr={index % 2 === 0 ? [0, 0, 0, 0, 3] : 0}
                    pl={index % 2 === 0 ? 0 : [0, 0, 0, 0, 3]}
                  >
                    <ItemWithDate
                      key={post.id}
                      title={post.frontmatter.title}
                      date={post.frontmatter.date}
                      type={post.frontmatter.type}
                      group={post.frontmatter.group}
                      slug={post.fields.slug}
                      excerpt={post.excerpt}
                    />
                  </Box>
                ))}
            </Box>
          </Column>
        </Row>
        <Row>
          <Column>
            <CustomLink to={pageUrls.news.url}>
              <ReadMoreButton />
            </CustomLink>
          </Column>
        </Row>
      </>
    );
  }
}

const NewsAndEventShortRoll = () => (
  <StaticQuery
    query={graphql`
      query NewsAndEventShortRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { 
            frontmatter: { 
              templateKey: { eq: "article-post" }
              group: { eq: "news" }
             } 
           }
          limit: 6
        ) {
          totalCount
          edges {
            node {
              excerpt(pruneLength: 200)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "YYYY-MMM-DD")
                type
                group
              }
            }
          }
        }
      }
    `}
    render={data => <NewsAndEventShortRollUI data={data} />}
  />
);

export default NewsAndEventShortRoll;
