import React, { Fragment } from 'react';
import Section from '../Atoms/Section';
import Container from '../Atoms/Container';
import Column from '../Atoms/Column';
import Box from '../Atoms/Box';
import CustomLink from '../Atoms/CustomLink';
import Row from '../Atoms/Row';
import ReadMoreButton from '../Rolls/ReadMoreButton';

function LeftLink({
  link, minHeight = 'auto', ...rest
}) {
  return (
    <Section p={0} minHeight={minHeight} {...rest}>
      <Container>
        <Row>
          <Column pl={3} order={1}>
            {link && (
              <Box m={0} width="100%">
                <CustomLink to={link.url}>
                  <ReadMoreButton text={link.text} />
                </CustomLink>
              </Box>
            )}
          </Column>
        </Row>
      </Container>
    </Section>
  );
}

export default LeftLink;


