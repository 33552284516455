import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import styled from 'styled-components';
import Box from '../../Atoms/Box';
import FlexBox from '../../Atoms/FlexBox';
import PreviewCompatibleImage from '../../Atoms/PreviewCompatibleImage';
import Paragraph from '../../Atoms/Paragraph';
import CustomLink from '../../Atoms/CustomLink';
import theme from '../../../styles/theme';
import Heading from '../../Atoms/Heading';
import { buildArticlePath } from '../../../utilis/helpers';

const StyledWrapper = styled(FlexBox)`
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.05);
  overflow: hidden;
`;

class VerticalItem extends PureComponent {
  render() {
    const { thumbnail, title, excerpt } = this.props;

    return (
      <StyledWrapper width={['100%', '100%', 220, 220]} height={[320, 320, 400]} mt={3} background={theme.color.white} flexDirection="column">
        <Box
          width="100%"
          height={[150, 170, 190]}
          color={theme.color.blue}
          backgroundColor={theme.color.blue}
          textAlign="center"
          alignSelf="flex-start"
        >
          {thumbnail && <PreviewCompatibleImage imageInfo={thumbnail} />}
        </Box>
        <FlexBox p={3} justifyContent="flex-start" flexDirection="column" flex={1}>
          <CustomLink to={buildArticlePath(this.props)} width="100%">
            <Heading h={3} color={theme.color.blue}>
              {title}
            </Heading>
          </CustomLink>
          <Box flex={1} width="100%" position="relative" overflowHidden mb={2}>
            <Paragraph fontSize={1} position="absolute" width="100%">{excerpt}</Paragraph>
          </Box>
          <Box flex="0 0 auto" width="100%">
            <CustomLink to={buildArticlePath(this.props)}>
              <FormattedMessage id="button:readon" defaultMessage="Read on" />
            </CustomLink>
          </Box>
        </FlexBox>
      </StyledWrapper>
    );
  }
}

export default VerticalItem;
