import React, { Fragment } from 'react';
import Section from '../Atoms/Section';
import Container from '../Atoms/Container';
import Column from '../Atoms/Column';
import Box from '../Atoms/Box';
import Heading from '../Atoms/Heading';
import CustomLink from '../Atoms/CustomLink';
import Row from '../Atoms/Row';
import ReadMoreButton from '../Rolls/ReadMoreButton';
import theme from '../../styles/theme';

function LeftLinkWithHeadline({
  headline, link, minHeight = 'auto', ...rest
}) {
  return (
    <Section p={0} minHeight={minHeight} {...rest}>
      <Container>
        <Row>
          <Column pl={3} pt={6} order={1}>
            {headline && headline !== '' && <Heading fontWeight={400} color={theme.color.blue}>{headline}</Heading>}
            {link && (
              <Box m={0} width="100%">
                <CustomLink to={link.url}>
                  <ReadMoreButton text={link.text} />
                </CustomLink>
              </Box>
            )}
          </Column>
        </Row>
      </Container>
    </Section>
  );
}

export default LeftLinkWithHeadline;
