import React from 'react';

import Section from '../Atoms/Section';
import Container from '../Atoms/Container';
import Row from '../Atoms/Row';
import Column from '../Atoms/Column';
import AnnouncementsShortRoll from '../Rolls/AnnouncementsShortRoll';
import FinancialReportsShortRoll from '../Rolls/FinancialReportsShortRoll';

function AnnouncementsAndReports() {
  return (
    <Section>
      <Container>
        <Row multi>
          { /* <Column col="is-12 is-half-widescreen"> */ }
          { /*  <AnnouncementsShortRoll /> */ }
          { /* </Column> */ }
          <Column>
            <FinancialReportsShortRoll />
          </Column>
        </Row>
      </Container>
    </Section>
  );
}

export default AnnouncementsAndReports;
