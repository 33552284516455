import React, { Component } from 'react';
import styled from 'styled-components';

import Section from '../Atoms/Section';
import Container from '../Atoms/Container';
import Row from '../Atoms/Row';
import Column from '../Atoms/Column';
import NewsAndEventShortRoll from '../Rolls/NewsAndEventShortRoll';

class NewsAndEvent extends Component {
  render() {
    return (
      <Section>
        <Container>
          <Row>
            <Column>
              <NewsAndEventShortRoll />
            </Column>
          </Row>
        </Container>
      </Section>
    );
  }
}

export default NewsAndEvent;
