import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

import { FormattedMessage } from 'react-intl';
import CustomLink from '../Atoms/CustomLink';
import Row from '../Atoms/Row';
import Column from '../Atoms/Column';
import theme from '../../styles/theme';
import { pageUrls } from '../../config/pageSetting';
import Heading from '../Atoms/Heading';
import VerticalItem from './Items/VerticalItem';
import ItemWithDate from './Items/ItemWithDate';
import FlexBox from '../Atoms/FlexBox';
import ReadMoreButton from './ReadMoreButton';

class FinancialReportsShortRollUI extends React.Component {
  render() {
    const { edges: posts } = this.props.data.allMarkdownRemark;

    return (
      <>
        <Row multi>
          <Column col="is-12">
            <Heading h={2} pb={0} color={theme.color.blue}>
              <FormattedMessage
                id="financial_reports:shortRoll.title"
                defaultMessage="Financial Reports"
              />
            </Heading>
            <FlexBox justifyContent="space-between" alignItems="baseline">
              {posts
                && posts.map(({ node: post }) => (
                  <VerticalItem
                    key={post.id}
                    title={post.frontmatter.title}
                    date={post.frontmatter.date}
                    type={post.frontmatter.type}
                    group={post.frontmatter.group}
                    thumbnail={post.frontmatter.thumbnail}
                    slug={post.fields.slug}
                    excerpt={post.excerpt}
                  />
                ))}
            </FlexBox>
          </Column>
        </Row>
        <Row>
          <Column>
            <CustomLink to={pageUrls.news.url}>
              <ReadMoreButton />
            </CustomLink>
          </Column>
        </Row>
      </>
    );
  }
}

const FinancialReportsShortRoll = () => (
  <StaticQuery
    query={graphql`
      query FinancialReportsShortRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: {
            frontmatter: {
              templateKey: { eq: "article-post" }
              group: { eq: "investor_relations" }
              type: { eq: "financial_reports" }
            }
          }
          limit: 4
        ) {
          totalCount
          edges {
            node {
              excerpt(pruneLength: 120)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "YYYY-MMM-DD")
                type
                group
                thumbnail {
                  alt
                  image {
                    childImageSharp {
                      fluid(maxWidth: 2048, quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => <FinancialReportsShortRollUI data={data} />}
  />
);

export default FinancialReportsShortRoll;
