import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, graphql } from 'gatsby';

import { PageHelmet } from '../components/PageHelmet';
import LeftTextRightImage from '../components/SectionBlocks/LeftTextRightImage';
import AnnouncementsAndReports from '../components/Investor/AnnouncementsAndReports';
import PageHeader from '../components/PageHeader';
import NewsAndEvent from '../components/Investor/NewsAndEvent';
import LeftTitleRightDesc from '../components/SectionBlocks/LeftTitleRightDesc';
import LeftLink from '../components/SectionBlocks/LeftLink';
import LeftLinkWithHeadline from '../components/SectionBlocks/LeftLinkWithHeadline';
import Heading from '../components/Atoms/Heading';

// ********************************* UI Template (Shared with CMS) *********************************
export const InvestorPageTemplate = ({
  title,
  heading,
  subheading,
  description,
  bgImage,
  ir,
  ir2,
  ir3,
  ir4,
  ir5,
  ir6,
  ir7,
  ir8,
  ir9,
  ir10,
  ir11,
  ir12,
  ir13,
  ir14,
  ir15,
  ir16,
  ir17,
}) => (
  <div>
    <PageHelmet title={title} description={description} />
    <PageHeader title={heading} subtitle={subheading} bgImage={bgImage} />
    { /*<LeftTextRightImage {...overview} /> */}
    <LeftLinkWithHeadline {...ir} />
    <LeftLink {...ir17} />
    <LeftLink {...ir16} />
    <LeftLink {...ir15} />
    <LeftLink {...ir14} />
    <LeftLink {...ir13} />
    <LeftLink {...ir12} />
    <LeftLink {...ir11} />
    <LeftLink {...ir10} />
    <LeftLink {...ir9} />
    <LeftLink {...ir8} />
    <LeftLink {...ir7} />
    <LeftLink {...ir6} />
    <LeftLink {...ir5} />
    <LeftLink {...ir4} />
    <LeftLink {...ir3} />
    <LeftLink {...ir2} />
    <AnnouncementsAndReports />
    <NewsAndEvent />
  </div>
);

// ********************************* Render page *********************************
const InvestorPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  { /*
  const newUrl = "https://investor.primechholdings.com/";
  if(typeof window !== 'undefined'){
    window.location.replace(newUrl);
  }
  */ }
  return <InvestorPageTemplate {...frontmatter} />;
};

export default InvestorPage;

// ********************************* Data graphql *********************************
export const pageQuery = graphql`
  query InvestorPageWithId($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        heading
        subheading
        bgImage {
          alt
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        description
        ir {
          headline
          link {
            url
            text
          }
        }
        ir2 {
          link {
            url
            text
          }
        }
        ir3 {
          link {
            url
            text
          }
        }
        ir4 {
          link {
            url
            text
          }
        }
        ir5 {
          link {
            url
            text
          }
        }
        ir6 {
          link {
            url
            text
          }
        }
        ir7 {
          link {
            url
            text
          }
        }
        ir8 {
          link {
            url
            text
          }
        }
        ir9 {
          link {
            url
            text
          }
        }
        ir10 {
          link {
            url
            text
          }
        }
        ir11 {
          link {
            url
            text
          }
        }
        ir12 {
          link {
            url
            text
          }
        }
        ir13 {
          link {
            url
            text
          }
        }
        ir14 {
          link {
            url
            text
          }
        }
        ir15 {
          link {
            url
            text
          }
        }
        ir16 {
          link {
            url
            text
          }
        }
        ir17 {
          link {
            url
            text
          }
        }
      }
    }
  }
`;
